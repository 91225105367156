import {
  Box,
  Stack,
  Heading,
  Text,
  Flex,
  Image,
  Divider,
  Center,
} from "@chakra-ui/react";
import BudgetIcon from "../../assets/images/budget.png";
import Budgets from "../../assets/images/budgets.png";
import CryptoAssets from "../../assets/images/crypto-assets.png";
import CryptoLong from "../../assets/images/features-nft.png";

export const Features = () => (
  <Box id="how-it-works">
    <Box as="section" maxW="8xl" mx="auto" bg={"#fff"} fontSize="lg">
      <Stack
        border={"2px"}
        spacing={{
          base: "12",
          md: "10",
        }}
        direction={{
          base: "column",
          md: "row",
        }}
        alignItems={"center"}
        px={{
          base: "4",
          md: "8",
        }}
      >
        <Box py={{ base: 8, md: 0 }} w={{ base: "100%", md: "50%" }}>
          <Flex
            flexDir={{ base: "column-reverse", md: "row" }}
            alignItems={"center"}
          >
            <Stack>
              <Heading size={"lg"}>
                Automated portfolio performance and transaction tracking.
              </Heading>
              <Text w={"90%"}>
              Automatically track crypto portfolio performance, asset value, and all your transactions across exchanges, wallets, and networks.  
              </Text>
            </Stack>
            <Image src={CryptoAssets} w={"128px"} pb={{ base: "8", md: "0" }} />
          </Flex>
        </Box>
        <Divider
          orientation="vertical"
          h={"300px"}
          bg={"gray.700"}
          opacity={1}
          borderWidth={"1px"}
          display={{ base: "none", md: "block" }}
        />
        <Box py={{ base: 8, md: 0 }} w={{ base: "100%", md: "50%" }}>
          <Flex
            flexDir={{ base: "column-reverse", md: "row" }}
            alignItems={"center"}
          >
            <Stack>
              <Heading size={"lg"}>
                Save money and manage your crypto spend with our powerful budgeting tool.
              </Heading>
              <Text w={{ base: "100%", md: "80%" }}>
              Easily track and orangize transactions, build personalized budgets and set financial goals with your crypto.
              </Text>
            </Stack>
            <Image src={Budgets} w={"180px"} pb={{ base: "8", md: "0" }} />
          </Flex>
        </Box>
      </Stack>
      <Center
        borderLeft={"2px"}
        borderRight={"2px"}
        borderBottom={"0px"}
        flexDir={"column"}
        textAlign={"center"}
        bg={"#fff"}
        py={"75px"}
      >
        <Image src={CryptoLong} mb={4} />
        <Heading size={"lg"} my={4}>
        Set financial goals with your crypto.
        </Heading>
        <Text w={{ base: "100%", md: "55%" }}>
        Saving for a rare NFT? A Car? A Vacation? Paying off debt? Allocate your crypto towards your financial goals and improve your spending habits.
        </Text>
      </Center>
      <Stack
        border={"2px"}
        spacing={{
          base: "12",
          md: "10",
        }}
        direction={{
          base: "column",
          md: "row",
        }}
        alignItems={"center"}
        px={{
          base: "4",
          md: "8",
        }}
      >
        <Box py={{ base: 8, md: 0 }} w={{ base: "100%", md: "50%" }}>
          <Flex
            flexDir={{ base: "column-reverse", md: "row" }}
            alignItems={"center"}
          >
            <Stack>
              <Heading size={"lg"}>
              Cryptocurrency management with built in tax compliance.
              </Heading>
              <Text w={"90%"}>
              Luumen helps you maintaince tax compliance with cost basis accounting methods. Download your crypo transactions and share it with your favorite tax software or tax advisor. 
              </Text>
            </Stack>
            <Image src={CryptoAssets} w={"128px"} pb={{ base: "8", md: "0" }} />
          </Flex>
        </Box>
        <Divider
          orientation="vertical"
          h={"300px"}
          bg={"gray.700"}
          opacity={1}
          borderWidth={"1px"}
          display={{ base: "none", md: "block" }}
        />
        <Box py={{ base: 8, md: 0 }} w={{ base: "100%", md: "50%" }}>
          <Flex
            flexDir={{ base: "column-reverse", md: "row" }}
            alignItems={"center"}
          >
            <Stack>
              <Heading size={"lg"}>
              Get a bird-eye view of your portfolio performance and spending habits with Luumen Snapshot™.
              </Heading>
              <Text w={{ base: "100%", md: "80%" }}>
              Luumen snapshot™ gives you a bird's eye view of your cryptocurrency portfolio, crypto finances, and crypto investments in a simple, easy-to-understand sharable dashboard.
              </Text>
            </Stack>
            <Image src={Budgets} w={"180px"} pb={{ base: "8", md: "0" }} />
          </Flex>
        </Box>
      </Stack>
    </Box>
  </Box>
);
