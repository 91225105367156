import { ButtonGroup, IconButton } from "@chakra-ui/react";
import * as React from "react";
import { FaGithub, FaTwitter, FaAngellist, FaLinkedin, FaReddit, FaInstagram, FaFacebook } from "react-icons/fa";

export const SocialMediaLinks = (props) => (
  <ButtonGroup variant="ghost" color="gray.600" {...props}>
    <IconButton
      as="a"
      href="https://angel.co/company/luumen"
      aria-label="Angellist"
      icon={<FaAngellist fontSize="20px" />}
      target="_blank"
    />
    <IconButton
      as="a"
      href="https://github.com/luumen"
      aria-label="GitHub"
      icon={<FaGithub fontSize="20px" />}
      target="_blank"
    />
    <IconButton
      as="a"
      href="https://www.linkedin.com/company/luumen-finance/"
      aria-label="Linkedin"
      icon={<FaLinkedin fontSize="20px" />}
      target="_blank"
    />
    <IconButton
      as="a"
      href="https://www.reddit.com/r/luumenfinance/"
      aria-label="Reddit"
      icon={<FaReddit fontSize="20px" />}
      target="_blank"
    />
    <IconButton
      as="a"
      href="https://www.instagram.com/luumenfinance/"
      aria-label="Instagram"
      icon={<FaInstagram fontSize="20px" />}
      target="_blank"
    />
    <IconButton
      as="a"
      href="https://www.facebook.com/Luumen-Finance-111378788044408"
      aria-label="Facebook"
      icon={<FaFacebook fontSize="20px" />}
      target="_blank"
    />
    
    <IconButton
      as="a"
      href="https://twitter.com/useLuumen"
      aria-label="Twitter"
      icon={<FaTwitter fontSize="20px" />}
      target="_blank"
    />
  </ButtonGroup>
);
