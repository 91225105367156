import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import "@fontsource/work-sans";

const theme = extendTheme({
  fonts: {
    heading: "Work Sans",
    body: "Work Sans",
  },
  colors: {
    orange: {
      50: "#FFF5E5",
      100: "#FFE2B8",
      200: "#FFD08A",
      300: "#FFBE5C",
      400: "#FFAB2E",
      500: "#FF9900",
      600: "#CC7A00",
      700: "#995C00",
      800: "#663D00",
      900: "#331F00",
    },
    gray: {
      50: "#f0f0fc",
      100: "#d4d5e1",
      200: "#b7b9c7",
      300: "#9b9db0",
      400: "#7f8199",
      500: "#65687f",
      600: "#4e5163",
      700: "#383a48",
      800: "#20232d",
      900: "#070c15",
    },
    green: {
      50: "#E8FDED",
      100: "#BEF8CD",
      200: "#95F4AC",
      300: "#6BF08C",
      400: "#41EC6B",
      500: "#18E74B",
      600: "#13B93C",
      700: "#0E8B2D",
      800: "#095D1E",
      900: "#052E0F",
    },
    purple: {
      50: "#F0E7FE",
      100: "#D6BBFB",
      200: "#BB90F9",
      300: "#A164F6",
      400: "#8639F4",
      500: "#6B0DF2",
      600: "#560BC1",
      700: "#400891",
      800: "#2B0561",
      900: "#150330",
    },
    pink: {
      50: "#FEE7FB",
      100: "#FBBBF5",
      200: "#F990EF",
      300: "#F664E8",
      400: "#F439E2",
      500: "#F20DDB",
      600: "#C10BAF",
      700: "#910884",
      800: "#610558",
      900: "#30032C",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
