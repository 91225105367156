import {
  Box,
  Stack,
  Text,
  Flex,
  Image,
  Spacer,
  Center,
  Heading,
  Button,
} from "@chakra-ui/react";
import { BsArrowRight } from "react-icons/bs";
import { CButton } from "../Button/Button";

export const PriceCard = (props) => {
  const { title, description, bgcolor, price, btn, children } = props;

  return (
    <Stack
      bg={"#FFFFFF"}
      justifyContent={"space-between"}
      border={"2px"}
      textAlign={"center"}
      {...props}
    >
      <Box>
        <Center p={5} flexDir={"column"} bg={bgcolor}>
          <Heading mb={"4"}>{title}</Heading>
          <Text>{description}</Text>
        </Center>
        <Box p={5} px={5}>
          <Flex alignItems={"flex-end"} justifyContent={"center"} mb={4}>
            <Heading size={"3xl"}>{price || "Free"}</Heading>
            {price && <Text as="i">/mo</Text>}
          </Flex>
          <Box textAlign={"center"} fontWeight={"bold"}>
            {children}
          </Box>
        </Box>
      </Box>
      <Box mt={"auto"} px={5} pb={7}>
        <a href="#">
          <CButton
            // disabled={props.disabled}
            mt={5}
            w={"100%"}
            bg="orange.500"
            rightIcon={<BsArrowRight />}
            sx={{ boxShadow: "0px 0px 0px #383A48" }}
            _hover={{ bg: "orange.300", boxShadow: "8px 8px 0px #383A48" }}
          >
            {btn || "Get Started Today"}
          </CButton>
        </a>
      </Box>
    </Stack>
  );
};
