import { Flex, Heading, Image } from "@chakra-ui/react";
import * as React from "react";
import logo from "../assets/images/logo.png";

export const Logo = (props) => (
  <Flex alignItems="center">
    <Image w="64px" mr="2" src={logo} />
    <Heading>Luumen</Heading>
  </Flex>
);
