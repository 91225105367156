import { Flex, Box, Heading, Text, Image } from "@chakra-ui/react";
import * as React from "react";
import { BsArrowRight } from "react-icons/bs";
import { CButton } from "../Button/Button";
import ControlImg from "../../assets/images/control.png";
import BG from "../../assets/images/gradient.png";

export const Control = () => (
  <Box id={"mission"}>
    <Flex
      mx="auto"
      maxW="8xl"
      py="12"
      as="section"
      justifyContent="space-between"
      alignItems="center"
      border={"2px"}
      borderTop={"none"}
      bg={"#F0F0F0"}
      bgImage={ControlImg}
      bgImage={{ base: "none", md: ControlImg }}
      bgPos={"180% center"}
      bgRepeat={"no-repeat"}
      bgSize={"75%"}
    >
      <Box
        textAlign="left"
        px={{
          base: "4",
          md: "8",
        }}
        py={{ base: "16", sm: "20" }}
      >
        <Heading
          fontSize={{ base: "4xl", sm: "5xl", lg: "6xl" }}
          fontWeight="black"
          maxW="2xl"
        >
          Get rewarded for building good crypto financial habits.
        </Heading>
        <Text my="10" fontSize="xl" maxW="xl">
          Managing your crypto shouldn’t be a drag. Unlock sweet NFTs and airdrops by keeping your crypto finances in check.
        </Text>
        <a href="https://discord.gg/luumen" target="_blank">
          <CButton bg="orange.500">
            Join Discord <Box w="50px"></Box>
            <BsArrowRight />
          </CButton>
        </a>
      </Box>
      {/* <Box
        // display={{ base: "none", lg: "block" }}
        overflow={"hidden"}
        position={"relative"}
        h={"500px"}
        w={"100%"}
      >
        <Image
          src={ControlImg}
          sx={{ transform: "scale(1)" }}
          position={"absolute"}
          right={"0"}
          top={"0"}
        />
      </Box> */}
    </Flex>
  </Box>
);
