import {
  Box,
  Stack,
  Text,
  Flex,
  Image,
  Spacer,
  Center,
  Heading,
  Button,
} from "@chakra-ui/react";
import { BsArrowRight } from "react-icons/bs";
import { CButton } from "../Button/Button";

export const TeamCard = (props) => {
  const { name, title, image, children } = props;

  return (
    <Stack
      bg={"#F0F0F0"}
      w={"330px"}
      justifyContent={"space-between"}
      border={"2px"}
      textAlign={"left"}
    >
      <Box>
        <Center p={5} flexDir={"column"} bg={"#DED1C0"}>
          <Image src={image}></Image>
        </Center>
        <Box p={5} px={5}>
          <Heading>{name}</Heading>
          <Box textAlign={"left"} fontWeight={"bold"}>
            {children}
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};
