import { Flex, Box, Heading, Stack, Text, Image } from "@chakra-ui/react";
import * as React from "react";
import { FaTwitter, FaLinkedin, FaAngellist } from "react-icons/fa";
import { CgMail } from "react-icons/cg";
import Casey from "../../assets/images/casey2.png";
import Carlos from "../../assets/images/carlos2.png";
import Tyler from "../../assets/images/tyler_headshot.jpeg";
import Hiring from "../../assets/images/hiring.png";
import { PriceCard } from "../Pricing/PriceCard";
import { TeamCard } from "./TeamCard";

export const Team = () => (
  <Box id="team">
    <Flex
      mx="auto"
      maxW="8xl"
      as="section"
      justifyContent="center"
      alignItems="center"
      border={"2px"}
      borderTop={"none"}
    >
      <Box
        py={["4", "6"]}
        px={{
          base: "6",
          md: "8",
        }}
      >
        <Heading
          fontSize={{ base: "4xl", sm: "5xl", lg: "6xl" }}
          fontWeight="black"
          maxW="5xl"
          textAlign="center"
        >
          Our Team
        </Heading>
        <Stack
          spacing={{
            base: "12",
            md: "10",
          }}
          direction={{
            base: "column",
            md: "row",
          }}
          mt="50px"
          mb={{
            base: "50px",
            md: "30px",
          }}
        >
          <TeamCard
            iconwidth="320px"
            name="Carlos Feliciano"
            bg="white"
            image={Carlos}
            pb="2px"
            flex={1}
          >
            <Text fontSize="md" my={2}>
              Co-Founder | CEO
            </Text>

            <Flex>
              <a
                href="https://twitter.com/gtlpanda"
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitter size="30px" style={{ marginRight: "16px" }} />
              </a>

              <a
                href="https://www.linkedin.com/in/carlosfelicianoii/"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedin size="30px" />
              </a>
            </Flex>
          </TeamCard>
          <TeamCard
            iconwidth="320px"
            name="Casey Wilcox"
            bg="white"
            image={Casey}
            pb="2px"
          >
            <Text fontSize="md" my={2}>
              Co-Founder | CTO
            </Text>
            <Flex>
              <a
                href="https://twitter.com/ioscasey"
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitter size="30px" style={{ marginRight: "16px" }} />
              </a>

              <a
                href="https://www.linkedin.com/in/caseyatdash/"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedin size="30px" />
              </a>
            </Flex>
          </TeamCard>
          <TeamCard
            iconwidth="320px"
            name="Tyler Pinckard"
            bg="white"
            image={Tyler}
            pb="2px"
          >
            <Text fontSize="md" my={2}>
              Advisor
            </Text>
            <Flex>
              <a
                href="https://twitter.com/manatee_one"
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitter size="30px" style={{ marginRight: "16px" }} />
              </a>

              <a
                href="https://www.linkedin.com/in/tylerpinckard/"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedin size="30px" />
              </a>
            </Flex>
          </TeamCard>
        </Stack>
      </Box>
    </Flex>
  </Box>
);
