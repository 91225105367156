import {
  Box,
  Stack,
  Heading,
  Text,
  Flex,
  Image,
  Divider,
  Center,
} from "@chakra-ui/react";
import Categorize from "../../assets/images/categorize.png";
import Budgets from "../../assets/images/budgets.png";
import CryptoAssets from "../../assets/images/crypto-assets.png";
import CryptoLong from "../../assets/images/crypto-assets-long.png";

export const MoreFeatures = () => (
  <Box id="how-it-works">
    <Box as="section" maxW="8xl" mx="auto" bg={"#FFFFFF"} fontSize="lg">
      <Center
        borderLeft={"2px"}
        borderRight={"2px"}
        flexDir={"column"}
        textAlign={"center"}
        h={"380px"}
        bg={"#FFFFFF"}
      >
        <Image src={Categorize} mb={4} />
        <Heading size={"lg"} my={4}>
          Your data is secure with Luumen Finance
        </Heading>
        <Text w={{ base: "100%", md: "55%" }}>
          Your data is secured with AES-256 encryption. We also encrypt all
          in-transit data using the latest TLS 1.2 All sensitive data is
          encrypted at rest.
        </Text>
      </Center>
      {/* <Stack
        border={"2px"}
        spacing={{
          base: "12",
          md: "10",
        }}
        direction={{
          base: "column",
          md: "row",
        }}
        alignItems={"center"}
        px={{
          base: "4",
          md: "8",
        }}
      >
        <Box py={{ base: 8, md: 0 }} w={{ base: "100%", md: "50%" }}>
          <Flex
            flexDir={{ base: "column-reverse", md: "row" }}
            alignItems={"center"}
          >
            <Stack>
              <Heading size={"lg"}>Crypto to Fiat Historical Data.</Heading>
              <Text w={"90%"}>
                To keep your business operating at full speed, we ensure that
                we’re providing you with the most current
              </Text>
            </Stack>
            <Image src={CryptoAssets} w={"128px"} pb={{ base: "8", md: "0" }} />
          </Flex>
        </Box>
        <Divider
          orientation="vertical"
          h={"250px"}
          bg={"gray.700"}
          opacity={1}
          borderWidth={"1px"}
          display={{ base: "none", md: "block" }}
        />
        <Box py={{ base: 8, md: 0 }} w={{ base: "100%", md: "50%" }}>
          <Flex
            flexDir={{ base: "column-reverse", md: "row" }}
            alignItems={"center"}
          >
            <Stack>
              <Heading size={"lg"}>Multi-Currency Budgets.</Heading>
              <Text w={{ base: "100%", md: "80%" }}>
                Build Custom budgets to help give you context and awareness on
                where your crypto is being spent.
              </Text>
            </Stack>
            <Image src={Budgets} w={"228px"} pb={{ base: "8", md: "0" }} />
          </Flex>
        </Box>
      </Stack>
      <Center
        borderLeft={"2px"}
        borderRight={"2px"}
        flexDir={"column"}
        textAlign={"center"}
        h={"330px"}
        bg={"#EEE4D6"}
      >
        <Image src={CryptoLong} mb={4} />
        <Heading size={"lg"} my={4}>
          Easily Track and Export your Crypto Expenses.{" "}
        </Heading>
        <Text w={{ base: "100%", md: "55%" }}>
          Add wallets like you would a checking or savings account and Luumen
          will automatically import your transactions for you to manage.
        </Text>
      </Center> */}
    </Box>
  </Box>
);
