import * as React from "react";
import "./App.css";
import {
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import { Footer } from "./components/Footer/Footer";
import { Hero } from "./components/Hero/Hero";
import { Navigation } from "./components/Navigation/Navigation";
import { Features } from "./components/Feature Cards/Features";
import { Control } from "./components/Control/Control";
import { Pricing } from "./components/Pricing/Pricing";
import { Team } from "./components/Team/Team";
import { MoreFeatures } from "./components/Feature Cards/MoreFeatures";

export const App = () => (
  <Box bg={"#F2F2F2"}>
    <Alert status="info" py={5}>
      <AlertIcon />
      <Box flex="1">
        <AlertTitle>
          Luumen Finance is now{" "}
          <a
            style={{ color: "blue", textDecoration: "underline" }}
            href="https://hatchfi.co"
          >
            Hatchfi.co
          </a>
          !
        </AlertTitle>
        <AlertDescription display="block">
          While Luumen Finance is closing down, we invite you to check out
          Hatchfi, the easiest way to connect your app to cryptocurrencies,
          exchanges, wallets, and protocols!{" "}
          <a
            style={{ color: "blue", textDecoration: "underline" }}
            href="https://blog.hatchfi.co/announcement-luumen-finance-has-rebranded-to-hatchfico"
          >
            Learn more here →
          </a>
        </AlertDescription>
      </Box>
    </Alert>
    <Navigation />
    <Hero />
    <Features />
    <Control />
    <MoreFeatures />
    <Pricing />
    <Team />
    <Footer />
  </Box>
);
