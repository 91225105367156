import {
  Flex,
  Box,
  Heading,
  Stack,
  Image,
  Center,
  Text,
} from "@chakra-ui/react";
import * as React from "react";
import { BsArrowRight } from "react-icons/bs";
import { CButton } from "../Button/Button";
import PricingImg from "../../assets/images/pricingbg.png";
import RM1 from "../../assets/images/rm1.png";
import RM2 from "../../assets/images/rm2.png";
import { Card } from "../Feature Cards/Card";
import { PriceCard } from "./PriceCard";

export const Pricing = () => (
  <Box bgRepeat="no-repeat" id="pricing">
    <Stack mx="auto" maxW="8xl" as="section" bg={"#F2F2F2"} border={"2px"}>
      <Center w={"100%"} flexDir={"column"} py={["10", "12"]}>
        <Heading
          fontSize={{ base: "4xl", sm: "5xl", lg: "6xl" }}
          fontWeight="black"
          textAlign="center"
        >
          Get Early Access
        </Heading>
        <Flex
          mt="50px"
          mb="10px"
          justifyContent={{ base: "center", md: "space-between" }}
          sx={{ gap: "20px" }}
          px={5}
          flexWrap={"wrap"}
        >
          <PriceCard
            title="Common"
            description="Test the Luumen waters with our FREE tier and start managing your crypto finances today!"
            bgcolor="#F4F4F9"
            icon={RM1}
            flex={1}
            btn={"Get Started Today"}
          >
            <Text fontSize="lg">2 account connections</Text>
            <Text fontSize="lg" my={3}>
              Automated portfolio tracking
            </Text>
            <Text fontSize="lg" my={3}>
              Financial Planning Tools and Budgeting
            </Text>
            <Text fontSize="lg" my={3}>
              NFT Performance Tracking
            </Text>
            <Text fontSize="lg" my={3}>
              Transaction Tracking
            </Text>
            <Text fontSize="lg" my={3}>
              Cost Basis Accounting for Taxes
            </Text>
            <Text fontSize="lg" my={3}>
              Basic Support + Community Access
            </Text>
            
          </PriceCard>
          <PriceCard
            title="Heroic"
            description="Perfect for the crypto-adventurer who wants to build a better financial future for themselves."
            bgcolor="#A7FAB4"
            price="$10"
            icon={RM1}
            flex={1}
            // opacity={0.4}
            // disabled={true}
          >
             <Text fontSize="lg">5 account connections</Text>
            <Text fontSize="lg" my={3}>
              Automated portfolio tracking
            </Text>
            <Text fontSize="lg" my={3}>
              Financial Planning Tools and Budgeting
            </Text>
            <Text fontSize="lg" my={3}>
              NFT Performance Tracking
            </Text>
            <Text fontSize="lg" my={3}>
              Transaction Tracking
            </Text>
            <Text fontSize="lg" my={3}>
              Cost Basis Accounting for Taxes
            </Text>
            <Text fontSize="lg" my={3}>
              Export Tax Reports
            </Text>
            <Text fontSize="lg" my={3}>
              Premium Support + Community Access
            </Text>
          </PriceCard>
          <PriceCard
            title="Epic"
            description="For those who are managing their crypto finances at scale with plenty of room for growth."
            bgcolor="#FFBAF8"
            price="$30"
            icon={RM1}
            flex={1}
            // opacity={0.4}
            // disabled={true}
          >
             <Text fontSize="lg">20 account connections</Text>
            <Text fontSize="lg" my={3}>
              Automated portfolio tracking
            </Text>
            <Text fontSize="lg" my={3}>
              Financial Planning Tools and Budgeting
            </Text>
            <Text fontSize="lg" my={3}>
              NFT Performance Tracking
            </Text>
            <Text fontSize="lg" my={3}>
              Transaction Tracking
            </Text>
            <Text fontSize="lg" my={3}>
              Cost Basis Accounting for Taxes
            </Text>
            <Text fontSize="lg" my={3}>
              Export Tax Reports
            </Text>
            <Text fontSize="lg" my={3}>
              Premium Support + Community Access
            </Text>
          </PriceCard>
          <PriceCard
            title="Legendary"
            description="The ultimate whale package for those who trade regularly with multiple exchanges and wallets."
            bgcolor="#faf0a7"
            price="$60"
            icon={RM1}
            flex={1}
            // opacity={0.4}
            // disabled={true}
          >
             <Text fontSize="lg">50 account connections</Text>
            <Text fontSize="lg" my={3}>
              Automated portfolio tracking
            </Text>
            <Text fontSize="lg" my={3}>
              Financial Planning Tools and Budgeting
            </Text>
            <Text fontSize="lg" my={3}>
              NFT Performance Tracking
            </Text>
            <Text fontSize="lg" my={3}>
              Transaction Tracking
            </Text>
            <Text fontSize="lg" my={3}>
              Cost Basis Accounting for Taxes
            </Text>
            <Text fontSize="lg" my={3}>
              Export Tax Reports
            </Text>
            <Text fontSize="lg" my={3}>
              Premium Support + Community Access
            </Text>
          </PriceCard>
        </Flex>
      </Center>
    </Stack>
  </Box>
);
