import { Button } from "@chakra-ui/react";

export const CButton = (props) => {
  return (
    <Button
      borderRadius="0"
      borderColor="gray.700"
      border="2px"
      color="gray.700"
      py="7"
      sx={{ boxShadow: "0px 0px 0px #383A48" }}
      _hover={{ bg: "orange.300", boxShadow: "8px 8px 0px #383A48" }}
      fontSize="lg"
      {...props}
    >
      {props.children}
    </Button>
  );
};
