import React from "react";
import {
  Box,
  Stack,
  Heading,
  Flex,
  Text,
  Center,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import { BiMenu, BiX } from "react-icons/bi";
import logo from "../../assets/images/logo.png";

export const Navigation = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleToggle = () => (isOpen ? onClose() : onOpen());

  return (
    <Center borderBottom="solid" bgColor="orange.500">
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        maxW="8xl"
        w="100%"
        py={6}
        px={{
          base: "4",
          md: "8",
        }}
        wrap="wrap"
        color="gray.700"
        {...props}
      >
        <Flex alignItems="center">
          <Image w="54px" mr="2" src={logo} />
          <Heading as="h1" size="lg" mr="5">
            Luumen Finance
          </Heading>
        </Flex>

        <Box display={{ base: "block", md: "none" }} onClick={handleToggle}>
          {isOpen ? <BiX size="28px" /> : <BiMenu size="24px" />}
        </Box>

        <Stack
          direction={{ base: "column", md: "row" }}
          display={{ base: isOpen ? "block" : "none", md: "flex" }}
          width={{ base: "full", md: "auto" }}
          alignItems="center"
          mt={{ base: 4, md: 0 }}
          spacing="6"
        >
          {/* <Text fontWeight="700" _hover={{ color: "#fff" }}>
            <a href="#how-it-works">Features</a>
          </Text>

          <Text fontWeight="700" _hover={{ color: "#fff" }}>
            <a href="#pricing">Pricing</a>
          </Text>
          <Text fontWeight="700" _hover={{ color: "#fff" }}>
            <a href="#team">Team</a>
          </Text>
          <Text fontWeight="700" _hover={{ color: "#fff" }}>
            <a href="https://luumenfinance.hashnode.dev/" target="_blank">
              Blog
            </a>
          </Text>
          <Text fontWeight="700" _hover={{ color: "#fff" }}>
            <a href="https://luumen.notion.site/Getting-Started-69d15928a1ee4f56b1b8bb8114e0ec0e" target="_blank">
              Docs
            </a>
          </Text>

          <Text fontWeight="700" _hover={{ color: "#fff" }}>
            <a href="https://discord.gg/m569cmPfCm" target="_blank">
              Join Discord
            </a>
          </Text>
          <Text display={{ base: "none", md: "block" }}>|</Text>
          <Text fontWeight="700" _hover={{ color: "#fff" }}>
            <a href="https://beta.luumen.io" target="_blank">
              Sign In
            </a>
          </Text> */}
        </Stack>
      </Flex>
    </Center>
  );
};
