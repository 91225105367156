import {
  Flex,
  Box,
  Heading,
  Text,
  Image,
  Input,
  SlideFade,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { CButton } from "../Button/Button";
import HeroImg from "../../assets/images/Hero.png";
import NftImage from "../../assets/images/nftssc.png";
import SnapshotImage from "../../assets/images/snapshotsc.png";

export const Hero = () => {
  const [showInput, setShowInput] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const submitSignUp = () => {
    setSubmitted(true);
    setShowInput(false);
  };

  const renderInput = (props) => {
    return (
      <SlideFade in={showInput}>
        <Input
          type="email"
          w="320px"
          placeholder="Email"
          borderRadius="0"
          borderColor="gray.700"
          border="2px"
          color="gray.700"
          py="7"
          mb={5}
          sx={{ boxShadow: "8px 8px 0px #383A48" }}
          _hover={{ bg: "orange.100" }}
          _active={{ boxShadow: "8px 8px 0px #383A48" }}
          _focus={{ boxShadow: "8px 8px 0px #383A48" }}
          fontSize="lg"
          name="email"
          required
          {...props}
        ></Input>
      </SlideFade>
    );
  };

  useEffect(() => {
    if (localStorage.getItem("beta")) {
      setSubmitted(true);
    }
  }, []);

  return (
    <Flex
      mx="auto"
      maxW="8xl"
      py="12"
      as="section"
      flexDirection={"column"}
      justifyContent="space-between"
      alignItems="center"
      overflow="hidden"
      borderf={"2px"}
      borderRight={"2px"}
      borderLeft={"2px"}
    >
      <Box
        textAlign="center"
        px={{
          base: "4",
          md: "8",
        }}
        py={{ base: "16", sm: "20" }}
        className="sib"
      >
        <Heading
          fontSize={{ base: "4xl", sm: "5xl", lg: "6xl" }}
          fontWeight="black"
          maxW="4xl"
          mx={"auto"}
        >
          Crypto accounting & financial planning made easy.
        </Heading>
        <Text mx={"auto"} my="10" fontSize="xl" maxW="3xl">
          Accurately track your crypto-assets and transactions across exchanges
          and wallets. Save money, stay tax compliant, and easily manage your
          crypto expenses with financial planning tools like budgeting,
          reporting, and more.
        </Text>

        {/* <form action="https://formsubmit.co/accounts@luumen.io" method="POST">
          <input
            type="hidden"
            name="_next"
            value="https://app.luumen.io/login?ref=early-access"
          />
          {showInput && renderInput()}

          {submitted ? (
            <Text as="i">
              Thanks for your interest! You can sign in at https://app.luumen.io and get started.
            </Text>
          ) : (
            <CButton
              type="submit"
              bg="orange.500"
              disabled={submitted}
              onClick={() => {
                if (!showInput) {
                  setShowInput(true);
                } else {
                  localStorage.setItem("beta", "true");
                }
              }}
            >
              {submitted ? "Thanks!" : "Get Started Today"}
            </CButton>
          )}
        </form> */}

        <Image
          mt={10}
          src={SnapshotImage}
          w={"3xl"}
          mx={"auto"}
          border={"solid"}
        />
      </Box>
    </Flex>
  );
};
