import { Text } from "@chakra-ui/layout";
import * as React from "react";

export const Copyright = (props) => (
  <Text fontSize="sm" {...props}>
    &copy; {new Date().getFullYear()} Luumen, Inc. - All rights reserved.{"  "}
    <a href="/privacy.html" style={{ color: "ff9900 !important" }}>
      Privacy Policy
    </a>
  </Text>
);
